import FullFiledIcon from "../../../../static/images/reffilPageIcons/FullFiledIcon.svg"
import s from "./GooglefullfiledAnswer.module.scss"
import Button from "@mui/material/Button";
import {useContext} from "react";
import RefillModalContext from "../../../../context/RefillModalContext";
import {initialFirstFormData} from "../../constRefill/stepOneModalConst";
import {generalFormDataStepTwo} from "../../constRefill/stepTwoModalConst";
const GoogleFulfilledAnswer = () => {
    const {
        setMode, setOpenModal, setTypeSystem,
        setTypeOrganization, setStepOneFormData, setStepTwoFormData
    } = useContext(RefillModalContext)

    const handleCloseModal = () => {
        setOpenModal(false)
        setMode("step1")
        setTypeSystem(undefined)
        setTypeOrganization(undefined)
        setStepOneFormData(initialFirstFormData)
        setStepTwoFormData(generalFormDataStepTwo)
    }

    const styleBtn ={
        color: "#fff",
        "&:hover": {
            background: "#fdba2f"
        },
        "&:disabled":{
            background: "#6666"
        }
    }

  return (
      <div className={s.fullfiledAnswerBlock}>
          <div className={s.iconContainer}>
              <img src={FullFiledIcon} alt="fullFiledIcon"/>
              <span className={s.text}>Поздравляем ваш аккаунт был создан!</span>
          </div>
          <span className={s.text}>
             В течение 1 - 3 минут на указанный e-mail придет приглашение от Google Ads со ссылкой для доступа к аккаунту.
          </span>
          <span className={s.text}>
            <strong>Внимание</strong> : в случае, если в течение 30 дней аккаунт не будет пополнен - он будет удалён
          </span>
          <div className={s.btnContainer}>
              <Button sx={styleBtn} type="button" onClick={() => setMode("refill")} className={s.btn}>пополнить сейчас</Button>
              <Button sx={styleBtn} type="button" onClick={ handleCloseModal } className={s.btn}>пополнить позже</Button>
          </div>
      </div>
  )
}

export default GoogleFulfilledAnswer