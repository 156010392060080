import CreateAccStepOne from "../CreateAccStepOne/CreateAccStepOne";
import CreateAccStepTwo from "../CreateAccStepTwo/CreateAccStepTwo";
import CreateAccStepThree from "../CreateAccStepThree/CreateAccStepThree";
import Refill from "../Refill/Refill";
import FulfilledAnswer from "../FulfilledAnswer/FullfiledAnswer";
import RejectAnswer from "../RejectAnswer/RejectAnswer";
import {useContext} from "react";
import RefillModalContext from "../../../../context/RefillModalContext";
import Loading from "../Loading/Loading";
import GoogleFulfilledAnswer from "../GoogleFulfiledAnswer/GoogleFullfiledAnswer";

const getTemplateModal = () => {

    const {mode} = useContext(RefillModalContext)

    const modalTemplate = {
        "step1": CreateAccStepOne,
        "step2": CreateAccStepTwo,
        "step3": CreateAccStepThree,
        "refill": Refill,
        "loading":  Loading,
        "reject": RejectAnswer,
        "fulfilled": FulfilledAnswer,
        "fulfilled_google": GoogleFulfilledAnswer
    }

  return modalTemplate[mode]
}

export default getTemplateModal