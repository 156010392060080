export const descriptionRefillAccStepThree = {
    "Google Ads": {title: "Google Ads", description: "В течение 10 минут после пополнения на указанный вами Email придет письмо с ссылкой на доступ в аккаунт."},
    "Yandex Direct": {title: "Яндекс Директ", description: "В течение 10 минут после пополнения на указанный вами Email придет письмо с информацией для доступа в аккаунт.",},
    "TikTok": {title: "TikTok", description: "В рабочие дни с 09:30 до 17:00 после пополнения на указанный вами E-mail придет письмо с ссылкой на доступ в аккаунт.",},
    "Facebook": {title: "Meta", description: "В течение 10 минут после пополнения на указанный вами Email придет письмо с ссылкой на доступ в аккаунт.",},
}

export const idAccRefillLinks = {
    "Google Ads": {
        link: "https://support.google.com/google-ads/answer/1704344?hl=ru",
        linkText: "Инструкция",
        text: "Как узнать id аккаунта Google?"
    },
    "Yandex Direct": {
        link: null,
        linkText: null,
        text: "ID аккаунта является логином, отправленным вам при создании аккаунта."
    },
    "TikTok": {
        link: "https://ads.tiktok.com/help/article/create-tiktok-ads-manager-account?lang=ru",
        linkText: "Инструкция",
        text: "Как узнать id аккаунта TikTok?"
    },
    "Facebook": {
        link: "https://www.facebook.com/business/help/1492627900875762",
        linkText: "Инструкция",
        text: "Как узнать id аккаунта Meta?"
    },
}